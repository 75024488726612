import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Canvas } from "@react-three/fiber"
import { Suspense } from "react"
import { OrbitControls, Stars } from "@react-three/drei"



const TestingPage = () => (
    <Layout>
        <Seo title="404: Not found" />
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
)

export default TestingPage
